.article-feature-hero {
    &__textbox {
        &:last-of-type {
            height: 150vh;
            padding-bottom: 25vh;
        }
    }

    &[data-component-booted] &__media {
        position: fixed;
    }

    &__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        overflow: hidden;
        &:not(:first-of-type) {
            visibility: hidden;
            opacity: 0;
            > div {
                transform: scale(1.1);
            }
            &:after {
                //display: block;
                //content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 15%;
                z-index: 1;
                /*background-image: linear-gradient(
                        to bottom,
                        hsla(0, 0%, 0%, 0) 0%,
                        hsla(0, 0%, 0%, 0.013) 8.1%,
                        hsla(0, 0%, 0%, 0.049) 15.5%,
                        hsla(0, 0%, 0%, 0.104) 22.5%,
                        hsla(0, 0%, 0%, 0.175) 29%,
                        hsla(0, 0%, 0%, 0.259) 35.3%,
                        hsla(0, 0%, 0%, 0.352) 41.2%,
                        hsla(0, 0%, 0%, 0.45) 47.1%,
                        hsla(0, 0%, 0%, 0.55) 52.9%,
                        hsla(0, 0%, 0%, 0.648) 58.8%,
                        hsla(0, 0%, 0%, 0.741) 64.7%,
                        hsla(0, 0%, 0%, 0.825) 71%,
                        hsla(0, 0%, 0%, 0.896) 77.5%,
                        hsla(0, 0%, 0%, 0.951) 84.5%,
                        hsla(0, 0%, 0%, 0.987) 91.9%,
                        hsl(0, 0%, 0%) 100%
                );*/
            }
        }
        .js-at-bottom & {
            position: absolute !important;
            top: auto;
            bottom: 0;
        }
        /*&:first-of-type {
            &:before {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 20%;
                z-index: 1;
                opacity: 0.5;
                background-image: linear-gradient(
                    to top,
                    hsla(0, 0%, 0%, 0) 0%,
                    hsla(0, 0%, 0%, 0.013) 8.1%,
                    hsla(0, 0%, 0%, 0.049) 15.5%,
                    hsla(0, 0%, 0%, 0.104) 22.5%,
                    hsla(0, 0%, 0%, 0.175) 29%,
                    hsla(0, 0%, 0%, 0.259) 35.3%,
                    hsla(0, 0%, 0%, 0.352) 41.2%,
                    hsla(0, 0%, 0%, 0.45) 47.1%,
                    hsla(0, 0%, 0%, 0.55) 52.9%,
                    hsla(0, 0%, 0%, 0.648) 58.8%,
                    hsla(0, 0%, 0%, 0.741) 64.7%,
                    hsla(0, 0%, 0%, 0.825) 71%,
                    hsla(0, 0%, 0%, 0.896) 77.5%,
                    hsla(0, 0%, 0%, 0.951) 84.5%,
                    hsla(0, 0%, 0%, 0.987) 91.9%,
                    hsl(0, 0%, 0%) 100%
                );
            }
            &:after {
                display: block;
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 40%;
                z-index: 1;
                background-image: linear-gradient(
                        to bottom,
                        hsla(0, 0%, 0%, 0) 0%,
                        hsla(0, 0%, 0%, 0.013) 8.1%,
                        hsla(0, 0%, 0%, 0.049) 15.5%,
                        hsla(0, 0%, 0%, 0.104) 22.5%,
                        hsla(0, 0%, 0%, 0.175) 29%,
                        hsla(0, 0%, 0%, 0.259) 35.3%,
                        hsla(0, 0%, 0%, 0.352) 41.2%,
                        hsla(0, 0%, 0%, 0.45) 47.1%,
                        hsla(0, 0%, 0%, 0.55) 52.9%,
                        hsla(0, 0%, 0%, 0.648) 58.8%,
                        hsla(0, 0%, 0%, 0.741) 64.7%,
                        hsla(0, 0%, 0%, 0.825) 71%,
                        hsla(0, 0%, 0%, 0.896) 77.5%,
                        hsla(0, 0%, 0%, 0.951) 84.5%,
                        hsla(0, 0%, 0%, 0.987) 91.9%,
                        hsl(0, 0%, 0%) 100%
                );
            }
        }*/
    }
}
