.dictionary {
    &__trigger {
        @apply inline font-semibold bg-blue-light text-black rounded-[1em] py-3 px-8;

        &:after {
            @apply inline-block;
            content: ' ';
        }

        svg {
            @apply inline mb-3 l:mb-2 -mr-6 select-none;
        }
    }

    &__popup {
        @apply block absolute w-full max-w-[300px] l:max-w-[400px] bg-blue-light p-25 l:p-30 pb-30 l:pt-25 left-50 -translate-x-50% translate-y-10;
        &-close {
            @apply block absolute top-[30px] right-[25px] l:right-[30px] w-18px h-18px;
            &:hover svg {
                transform: rotate(90deg);
            }
            svg {
                @apply w-full h-full;
                transition: transform 0.25s ease-out;
            }
        }
    }
}
