@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    .btn {
        @apply flex items-start whitespace-nowrap justify-center border-1 border-transparent bg-white text-black font-beckett text-20 leading-50 px-40 h-54px rounded-30px transition-colors duration-300 ease-out-cubic;
        &--small {
            @apply text-18 leading-38 px-30 h-40px rounded-20px;
        }
        &--slim {
            @apply text-16 leading-28 px-20 h-32px rounded-20px;
        }
        &--small-mobile {
            @screen mobile {
                @apply text-18 leading-38 px-30 h-40px rounded-20px;
            }
        }
    }

    .default-link {
        @apply underline transition-colors duration-400 ease-out hover:decoration-transparent;
        text-underline-offset: 0.2em;
        &__faux {
            a & {
                @apply underline transition-colors duration-400 ease-out;
                text-underline-offset: 0.2em;
            }
            html.using-mouse a:hover &,
            html.outline a:focus & {
                @apply decoration-transparent;
            }
        }
    }

    .inverted-links {
        a {
            @apply underline decoration-transparent transition-colors duration-400 ease-out hover:decoration-current;
            text-underline-offset: 0.2em;
        }
        &__faux {
            @apply underline decoration-transparent transition-colors duration-400 ease-out;
            text-underline-offset: 0.2em;
            html.using-mouse a:hover &,
            html.outline a:focus & {
                @apply decoration-current;
            }
        }
    }

    .checkbox {
        position: relative;
        display: flex;
        align-items: flex-start;
        input {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 20px;
            height: 20px;
            cursor: pointer;
        }
        .checkmark {
            display: none;
        }
        input:checked + svg .checkmark {
            display: block;
        }
    }

    .newsletter-field {
        @apply w-full pt-10  pb-8;
        &::placeholder {
            @apply transition-colors duration-400 ease-out;
        }
        &:focus {
            &::placeholder {
                color: transparent;
            }
        }
    }

    .form-field {
        @apply mb-30;

        textarea, input[type="text"], input[type="email"], input[type="search"], input[type="tel"] {
            @apply w-full px-15 pt-10 pb-13 border-1 border-grey focus:border-black body transition-colors duration-400 ease-out;
            &.error {
                @apply border-error;
            }
        }
        &__label {
            @apply block h6 mb-20;
            &.required:after {
                display: inline-block;
                width: 7px;
                height: 7px;
                content: '';
                vertical-align: super;
                margin-left: 3px;
                background-image: url("data:image/svg+xml,%3Csvg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.94 4.44231.67667 5.83333 0 4.6891 2.35667 3.5 0 2.33333.67667 1.1891 2.94 2.58013 2.8 0h1.37667L4.06 2.58013 6.3 1.1891 7 2.33333 4.62 3.5 7 4.6891l-.7 1.14423-2.24-1.39102L4.17667 7H2.8l.14-2.55769Z' fill='%236D7784'/%3E%3C/svg%3E");
            }
        }
        &__instructions {
            @apply -mt-10 mb-20;
        }

        &__errors {
            @apply mt-10 body-small text-error;
        }

        &__select {
            @apply relative w-full border-1 border-grey;
            &.error {
                @apply border-error;
            }
            select {
                @apply w-full px-15 pt-10 pb-13 appearance-none bg-white;
            }
            &-arrow {
                @apply pointer-events-none absolute bg-white w-40px;
                top: 3px;
                right: 3px;
                bottom: 3px;
                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 9px;
                    height: 16px;
                }
            }
        }
    }

    .h1 {
        @apply font-bold text-60 m:text-120 leading-1;
    }

    .h2 {
        @apply font-bold text-60 l:text-80 leading-1 l:leading-90;
    }

    .h3 {
        @apply font-bold text-40 l:text-70 leading-45 l:leading-75;
        &.font-tobias {
            @apply font-normal;
        }
    }

    .h4 {
        @apply font-bold text-30 m:text-40 leading-40 m:leading-50;
        &.font-tobias {
            @apply font-normal;
        }
    }

    .h5 {
        @apply font-bold text-25 l:text-30 leading-35 l:leading-40;
        &.font-tobias {
            @apply font-normal;
        }
    }

    .h6 {
        @apply font-bold text-18 l:text-20 leading-28 l:leading-30;
        &.font-tobias {
            @apply font-normal;
        }
    }

    .th {
        @apply font-bold text-14 l:text-20 leading-22 l:leading-30;
    }

    .subheading {
        @apply text-20 m:text-25 leading-30 m:leading-35;
    }

    .quote {
        @apply font-semibold text-25 l:text-50 leading-35 l:leading-60;
    }

    .body {
        @apply text-16 m:text-18 leading-24 m:leading-28;
        // font-feature-settings: 'pnum' on, 'lnum' on;
        &.font-tobias {
            @apply font-normal;
        }
    }

    .body-small {
        @apply text-14 l:text-16 leading-22 l:leading-24;
        // font-feature-settings: 'pnum' on, 'lnum' on;
        &.font-tobias {
            @apply font-normal;
        }
    }

    .font-tobias {
        // @apply subpixel-antialiased;
    }

    .outline {
        outline: none !important;
    }

    .image-background {
        @apply bg-black bg-opacity-15;
    }

    .video-embed {
        @apply fixed full flex items-center opacity-0 invisible z-100 bg-blacked bg-opacity-90;
        iframe {
            @apply absolute full;
        }
    }

    .slideshow-button {
        @apply relative w-40px h-40px flex-none inline-flex justify-center items-center pointer-events-auto;
        transition: opacity 0.3s ease-in-out;
        svg {
            @apply relative z-1 text-black;
        }
        &:before {
            content: '';
            transition: transform 0.2s ease-in-out;
            @apply absolute full bg-pink rounded-20px;
        }
        &:disabled {
            @apply opacity-0;
        }
        &:hover:not(:disabled) {
            &:before {
                transform: scale(1.1);
            }
        }
    }

    .subscription-image {
        padding-bottom: calc(100% / (var(--ratio-mobile)));
        @media (min-width: 750px) {
            padding-bottom: calc(100% / (var(--ratio-default)));
        }
    }

    .subscription-sale-start {
        @apply subheading;
        strong {
            @apply h5;
        }
    }

    .tab-item {
        @apply block body-small m:h6 py-3 m:py-11 m:px-50 mp:px-90 border-1 w-full text-center;
        &[aria-selected] {
            @apply bg-black text-white border-black;
        }
    }

    .floorplan {
        @apply w-full max-w-[1029px] mx-auto;
        g {
            @apply transition-colors duration-350 ease-out;
            &:not([aria-selected]) {
                @apply fill-white;
            }
        }
    }

    .reminder-overlay {
        @apply fixed full flex items-center z-1000 bg-blacked bg-opacity-65;
    }

    .secondary-price-and-info-bg {
        @apply absolute z-0 top-0 l:left-0 w-100vw h-full center-x l:transform-none;
        @screen l {
            width: calc(100% + 40px) !important;
        }
        @screen lp {
            width: calc(100% + 60px) !important;
        }
        @media (min-width: 1560px) {
            width: calc(100% + ((100vw - 1440px)/2)) !important;
        }
    }

    .tasteguide-result-nav-link {
        @apply relative mx-20;
        &.selected {
            @apply text-black !no-underline;
            &:after {
                content: ' ';
                @apply absolute center-x bottom-[-5px] w-5px h-5px rounded-5px bg-current;
            }
        }
    }

    .tasteguide-category-nav {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50%;
            height: 100%;
            opacity: var(--opacityLeft);
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 50%;
            height: 100%;
            opacity: var(--opacityRight);
        }
    }

    .slide {
        width: calc(40vw * var(--ratio));
        @screen ls {
            width: calc(50vh * var(--ratio));
        }
    }

    .mhForm {
        background: transparent !important;
        width: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        gap: 2rem !important;
        font-family: Beckett, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
        font-size: 16px !important;
        color: inherit !important;
        position: relative;
    }
    .mhForm__control:first-child span strong {
        font-size: 30px !important;
        font-weight: 700 !important;
    }
    .mhForm__button {
        font-size: 18px !important;
        height: 40px !important;
        line-height: 38px !important;
        padding: 0 35px !important;
        width: 175px !important;
    }

    .mhForm__control--button {
        margin-bottom: 0 !important;
    }

    .mhForm__label {
        color: currentColor !important;
    }

    .mhForm__validationError {
        @apply !text-black;
    }

    .page-header__menu .mhForm__validationError {
        @apply !text-yellow;
    }

    .mhForm__success ~ * {
        display: block !important;
    }

    .mhForm__success {
        @apply absolute full !text-black !bg-red !bg-opacity-95 flex justify-center items-center text-center !font-bold;
        span {
            max-width: 43ch;
        }
    }

    .page-header__menu .mhForm__success {
        @apply !bg-black !bg-opacity-95 !text-yellow-light;
    }

    .page-header__menu .mhForm__button {
        @apply bg-yellow-light text-black hover:bg-transparent hover:border-yellow-light hover:text-yellow-light;
    }

    .mhForm__success + .mhForm__control {
        grid-column: 1 / 3 !important;
        strong {
            font-size: 30px !important;
            font-weight: 700 !important;
        }
    }

    .mhForm__input--text,
    .mhForm__input--email {
        border-bottom: 1px solid currentColor !important;
    }

    .stories-shader {
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }

    .grey-drop-shadow {
        box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.25);
    }

    :root {
        --textColor: theme('colors.purple');
        --bgColor: theme('colors.white');
        --accentColor: theme('colors.purple');
        --buttonColor: theme('colors.purple');
    }

    .theme-grey {
        --textColor: theme('colors.purple');
        --bgColor: #ebedef;
        --accentColor: theme('colors.purple');
        --buttonColor: theme('colors.purple');
    }

    .theme-green {
        --textColor: theme('colors.white');
        --bgColor: theme('colors.green.DEFAULT');
        --accentColor: theme('colors.beige.light');
        --buttonColor: theme('colors.beige.light');
    }

    .theme-purple {
        --textColor: theme('colors.white');
        --bgColor: theme('colors.purple');
        --accentColor: theme('colors.blue.DEFAULT');
        --buttonColor: theme('colors.white');
    }

    .theme-beige {
        --textColor: theme('colors.black');
        --bgColor: theme('colors.beige.light');
        --accentColor: theme('colors.green.DEFAULT');
        --buttonColor: theme('colors.green.DEFAULT');
    }
}
