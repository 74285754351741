:root {
    --strip-banner-height: 0px;
}
body {
    font-variant-ligatures: none;
    font-feature-settings: "liga" 0;
    padding-top: calc(96px + (var(--strip-banner-height)));
    @screen m {
        padding-top: calc(142px + (var(--strip-banner-height)));
    }
    &.pt-0 {
        padding-top: var(--strip-banner-height);
        @screen m {
            padding-top: var(--strip-banner-height);
        }
    }
}
.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-500 ease-in-out-cubic;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

.image-zoom {
    img {
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        max-width: none;
        will-change: opacity, transform;
        &.lazyloaded {
            transition: transform 0.6s theme('transitionTimingFunction.out-quad'), opacity 1s theme('transitionTimingFunction.out-quad');
        }
    }
    svg {
        transition: transform 0.45s theme('transitionTimingFunction.in-out-quad');
    }
    html.using-mouse &:hover,
    html.outline &:focus {
        img {
            transform: scale(1.015);
        }
        svg {
            transform: translate(-50%, -50%) scale(1.15);
        }
    }
}

input[type=text], input[type=email] {
    background: transparent;
    margin: 0;
    &::placeholder {
        color: currentColor;
        opacity: 1;
    }
}

.no-outline * {
    outline: none !important;
}

[hidden] {
    display: none !important;
}
