.pagination {
    li > * {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        display: block;
        margin: 0 4px;
        text-align: center;
        &[hidden] {
            display: none;
        }
        @apply text-16 leading-24;
        span {
            display: inline-block;
            padding-top: 2px;
        }
    }

    a:not(.selected) {
        transition: background-color 0.25s ease-out;
        &:hover {
            @apply bg-grey-light;
        }
    }

    li:first-child > *, li:last-child > * {
        margin: 0;
    }

    .selected {
        @apply bg-black text-white;
    }
}
