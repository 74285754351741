/* =================
    Flickity reset styles
    https://flickity.metafizzy.co
================== */

.flickity-enabled {
    position: relative;
}

.flickity-enabled:focus {
    outline: none;
}

.flickity-viewport {
    overflow: visible;
    position: relative;
    width: 100%;
    height: 100%;
}

.flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    [data-component="TasteGuideSamples"] &, [data-component="TasteGuideCategories"] &  {
        display: flex;
        position: relative;
    }
}

/* draggable */

.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
}

.flickity-prev-next-button {
    @apply sr-only;
}

.flickity-page-dots {
    @apply flex items-center flex-nowrap absolute -bottom-[40px] center-x bg-grey bg-opacity-50 px-24 py-14 rounded-40px h-40px;
}

.flickity-page-dots .dot {
    @apply w-12px h-12px mr-12 last:mr-0 bg-white rounded-6px cursor-pointer;
}

.flickity-page-dots .dot.is-selected {
    @apply bg-purple;
}

[data-component="seasonbook/SeasonBookSlider"] {
    .is-draggable {
        @apply mb-80;
    }
    .flickity-page-dots {
        @apply -bottom-[100px] l:-bottom-[120px];
    }
    .flickity-prev-next-button {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 0;
        overflow: hidden;
        clip: auto;
        top: 50%;
        @apply bg-pink text-black rounded-20px disabled:opacity-0 translate-x-10 m:translate-x-50% left-0 lp:left-[20px];
        &.next {
            @apply -translate-x-10 m:-translate-x-50% left-auto right-0 lp:right-[20px];
        }
    }
}
