.wysiwyg {
    p, ul, ol, table {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p {
        & + p {
            margin-top: calc(-40px + 1.5em);
        }
        &.ingress {
            @apply h6 font-tobias !font-medium;
        }
    }

    ul {
        padding-left: 1.2em;
        list-style: disc;
        li {
            display: list-item;
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;
        li {
            counter-increment: ol-counter;
            display: flex;
            &:before {
                content: counter(ol-counter) " ";
                font-weight: 500;
                font-size: 0.9em;
                margin-top: 0.1em;
                margin-right: 0.6em;
            }
        }
    }

    li {
        margin-bottom: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        @apply underline underline-offset-2 transition-opacity duration-300 ease-out-cubic hover:opacity-60;
    }

    h2 {
        @apply h5 font-tobias font-normal mb-20 l:mb-30;
    }

    h3 {
        @apply h6 mb-20;
    }

    b, strong {
        @apply font-bold;
    }

    i, em {
        @apply italic;
    }

    table {
        width: 100%;
        tr {
            border-bottom: 1px solid;
            &:last-child {
                border-bottom: 0;
            }
            td {
                padding: 11px 0;
                @screen m {
                    padding: 17px 0;
                }
            }
            &:first-child td {
                padding-top: 0;
            }
            &:last-child td {
                padding-bottom: 0;
            }
        }
        td:last-child {
            text-align: right;
        }
    }

    &.blocks-text a {
        color: #6a729e;
    }

    &--archive {
        b, strong {
            @apply font-medium;
        }
    }

    &--tasteguide {
        h3 {
            @apply h6 mb-25;
            @screen l {
                @apply mb-5;
            }
        }
    }
}
