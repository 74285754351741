@keyframes pulsate {
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
}

.map {

    &__wrap {

        height: 90vh;
        @supports (--custom:property) {
            height: calc(var(--vh, 1vh) * 90);
        }
        @screen ls {
            height: 0;
            padding-bottom: calc(820/1500 * 100%);
            @screen l {
                padding-bottom: calc(620/1500 * 100%);
            }
        }
    }

    &__help {
        transition: opacity 0.3s;
    }

    &__marker {
        width: 0;
        height: 0;

        > * {
            display: block;
            width: 60px;
            height: 60px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -25px 0 0 -25px;

            * {
                pointer-events: none;
            }
        }

        &-icon {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 2px;
            left: 0;
            background-image: url('../img/map-marker.svg');
            background-size: 100% 100%;
            transition: opacity 0.3s, transform 0.3s;
            z-index: 2;
        }

        &-close {
            display: block;
            width: 50px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            margin-left: -25px;
            background-image: url('../img/map-marker-close.svg');
            background-size: 100% 100%;
            opacity: 0;
            transform: scale(0.5) translate(0, 25px);
            transition: opacity 0.3s, transform 0.3s;
            z-index: 2;
        }

        &-dot {
            display: block;
            background: rgba(0, 0, 0, 0.12);
            border-radius: 50%;
            height: 14px;
            width: 14px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: 12px 0 0 -7px;
            transform: rotateX(55deg);
            transition: opacity 0.3s;
            z-index: -2;

            &::after {
                content: "";
                border-radius: 50%;
                height: 40px;
                width: 40px;
                position: absolute;
                margin: -13px 0 0 -20px;
                animation: pulsate 1.2s ease-out;
                animation-iteration-count: infinite;
                opacity: 0;
                box-shadow: 0 0 1px 2px #89849b;
                animation-delay: 1.1s;
            }
        }

        &.is-active &-icon {
            opacity: 0;
            transform: scale(0.5) translate(0, 15px);
        }

        &.is-active &-close {
            opacity: 1;
            transform: scale(1) translate(0, 0);
        }

        &.is-active &-dot {
            opacity: 0;
        }
    }

    &__popup {
        .mapboxgl-popup-tip {
            display: none;
        }

        .mapboxgl-popup-content {
            padding: 0;
            border-radius: 0;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
        }

        .g-col, .g-wrap, .block-links {
            display: block;
            width: 100%;
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            margin: 0;
            color: $colorGreen;

            * {
                font-size: 14px;
                line-height: 1.5;
            }

            .block-links__icon {
                width: 20px;
                top: 0;
            }

            .block-links__label {
                padding-left: 35px;
            }

            li:not(:last-child) {
                margin: 0 0 15px 0;
            }

            .soundclip__player {
                height: 21px;
                padding-top: 3px;
            }

            .soundclip__player-bar {
                top: -2px;
                margin-left: 16px;
            }

            .soundclip__scrubber {
                height: 19px;
            }
        }

        .block-links {
            padding-right: 10px;
        }
    }

}
