.tasteguide-report {
    &__link {
        @apply text-18 l:text-20 leading-28 underline;
    }
    &__criteria {
        width: 100%;
        &:nth-child(1) {
            width: 53%;
            height: 40vh;
            max-height: 240px;
            @screen m {
                width: 52%;
                height: 50vh;
                max-height: 468px;
            }
            @screen l {
                max-height: 300px;
            }
        }
        &:nth-child(2) {
            width: 47%;
            height: 24vh;
            max-height: 144px;
            @screen m {
                width: 48%;
                height: 30vh;
                max-height: 281px;
            }
            @screen l {
                max-height: 180px;
            }
        }
        &:nth-child(3) {
            width: 47%;
            height: 16vh;
            max-height: 96px;
            @screen m {
                width: 48%;
                height: 20vh;
                max-height: 187px;
            }
            @screen l {
                max-height: 120px;
            }
        }
    }

    &__share {
        &-column {
            width: 100%;
            @screen l {
                width: calc(50% - 20px);
            }
        }
    }
}
