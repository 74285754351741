@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

.archive-accordions {

    html.no-js &__item-arrow {
        transform: rotate(-90deg);
    }

    &__item {
        &-arrow {
            @apply -mb-2;
            [data-component-booted] & {
                transition: transform 0.15s ease-in-out;
            }
            .js-is-expanded & {
                transform: scaleY(-1);
            }
        }
        &-loader {
            transition: opacity 0.25s ease-in-out;
            opacity: 0;
            position: relative;
            top: -1px;
            @apply mr-auto ml-8 text-red;
            @screen l {
                top: -2px;
            }
            svg {
                width: 22px;
                height: 22px;
                transform-origin: center center;
                animation: rotate 2s linear infinite;

                @screen l {
                    width: 25px;
                    height: 25px;
                }

                circle {
                    stroke-width: 3px;
                    stroke-dasharray: 1, 200;
                    stroke-dashoffset: 0;
                    stroke-linecap: round;
                    animation: dash 1.5s ease-in-out infinite;
                }
            }

            .js-is-loading & {
                opacity: 1;
            }
        }
    }
}
