.tasteguide {
    &__player {
        pointer-events: none;
        &.js-started {
            pointer-events: auto;
            cursor: col-resize;
        }
    }
    &__input {
        @apply block relative cursor-pointer;
        input {
            @apply absolute full pointer-events-none;
            appearance: none;
        }
        span {
            @apply font-bold text-16;
            transition: all 0.15s ease-out;
        }
        input[type="radio"]:checked + span {
            @apply bg-pink;
        }
        input[type="checkbox"]:checked + span {
            @apply text-pink;
        }
    }
}
