.page-header {
    position: absolute;
    top: var(--strip-banner-height);
    left: 0;
    width: 100%;
    height: 96px;
    z-index: 200;

    @apply transition-colors duration-300 ease-out-cubic;

    @screen m {
        height: 142px;
    }

    body.menu-open & {
        position: fixed;
        @apply text-black;
    }

    &__logo {
        width: 60px;
        height: 36px;
        @screen m {
            width: 100px;
            height: 60px;
        }
        body.frontpage & {
            &-anim {
                display: none;
                @screen m {
                    display: block;
                    position: absolute;
                    width: 335px;
                    height: 268px;
                    pointer-events: none;
                    top: -28px;
                    left: -27px;
                }
            }
            @screen m {
                transform-origin: top left;
                transform: scale(2.8) translateY(7.5px);
                &.playing {
                    opacity: 0;
                }
            }
        }
        body.jubilee & {
            width: 153px;
            height: 92px;
            transform-origin: top left;
            transform: translateY(28px);
            @screen m {
                transform: translateY(5px);
            }
            @screen ls {
                width: 227px;
                height: 136px;
                transform: translateY(28px);
                @screen l {
                    transform: translateY(59px);
                }
            }
        }
    }

    &__hamburger {
        padding: 6px 0;
        i {
            display: block;
            width: 36px;
            height: 2px;
            background: currentColor;
            margin-bottom: 6px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__menu {
        overscroll-behavior-y: contain;
        top: var(--strip-banner-height);
        left: 0;
        width: 100%;
        bottom: 0;
    }
}
