.audio-player {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    button {
        @apply hitstate;
        &:disabled {
            opacity: 50%;
        }
    }

    &__progress {
        position: relative;
        display: block;
        appearance: none;
        background: #fff;
        cursor: pointer;
        width: 100%;
        border: none;
        height: 4px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: var(--progress);
            height: 4px;
            pointer-events: none;
            @apply bg-pink;
        }

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 30px;
            margin-top: -15px;
            opacity: 0;
        }

        &::-webkit-slider-runnable-track/*, &&::-moz-range-track, &::-ms-track*/ {
            background-color: #fff;
            border-radius: 0;
            height: 4px;
        }


        &::-webkit-slider-thumb/*, &::-moz-range-thumb, &::-ms-track*/ {
            appearance: none;
            margin-top: 0;
            border-radius: 0;
            border: none;
            height: 4px;
            width: 1px;
            opacity: 0;
        }
    }

    &__playlist {
        @apply bg-black text-white;
        position: absolute;
        left: 0;
        bottom: 100%;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 45px;
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 14px 0;
            border-bottom: 1px solid rgba(255,255,255, 0.25);
            button:first-child {
                text-align: left;
                flex: 1 1 auto;
                margin-right: 20px;
                @apply truncate;
                &:after {
                    display: none;
                }
            }
        }
    }
}
